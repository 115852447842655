@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
}


/* global styles */
h1, h2, h3, h4, h5, h6, a, p {
  text-decoration: none;
  color: black;
  margin: 0;
  line-height: 1.5;
}

img {
  width: 15rem;
  height: 15rem;
  padding: 5px;
}

.card-container {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.me-card {
  display: flex;
  justify-content: center;
}

.me-card img {
  margin-right: 50px;
  border-radius: 100%;
}

.text-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.card {
  margin: 20px;
}

.followers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 70%;
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid lightgrey;
}

